import {Fragment, useRef, useState} from 'react';
import Button from "../UI/Button";
import React from "react";
import Modal from "../UI/Modal";
import {createCustomerProduct, getCustomerProduct, getProduct, updateCustomerProduct} from "../../api/eventGroupApiClient";
import ListEdit from "../UI/ListEdit";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import Box from "../UI/Box";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useProducts} from "../../hooks/useProducts";
import {convertToDisplayDate, PRODUCT_STATUS_ACTIVE, PRODUCT_TYPE_CREDIT_PASS} from "../../utility/utility";
import {useCustomerProducts} from "../../hooks/useCustomerProducts";
import {useCustomers} from "../../hooks/useCustomers";
import {useProductActivationTypes} from "../../hooks/useProductActivationTypes";
import {usePaymentMethods} from "../../hooks/usePaymentMethods";

const CustomerProductList = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [customerProduct, setCustomerProduct] = useState(null);
    const [submitCount, setSubmitCount] = useState(0);
    const [filterCustomerId, setFilterCustomerId] = useState(null);
    const [filterFirstName, setFilterFirstName] = useState(null);
    const [filterLastName, setFilterLastName] = useState(null);
    const selectProductRef = useRef(null);
    const selectCustomerRef = useRef(null);
    const selectPaymentMethodRef = useRef(null);
    const selectActivationTypeRef = useRef(null);
    const creditRef = useRef(null);
    const validToRef = useRef(null);
    const orderDateRef = useRef(null);
    const firstNameFilterRef = useRef(null);
    const lastNameFilterRef = useRef(null);
    const [product, setProduct] = useState(null);
    const {products} = useProducts(PRODUCT_STATUS_ACTIVE, 1, 200, 0);
    const {customerProducts, pagination} = useCustomerProducts(filterCustomerId, filterFirstName, filterLastName, page, pageSize, submitCount);
    const {customers} = useCustomers(1, 200, null, null, 'active', null, 0);
    const {productActivationTypes} = useProductActivationTypes(0);
    const {paymentMethods} = usePaymentMethods(1, 0);

    const navigate = useNavigate();

    const customerProductList = customerProducts.map((item) => {
        return {
            customerProductId : item.customerProductId,
            customerName      : item.customerName,
            productName       : item.name,
            validTo           : item.validTo ? convertToDisplayDate(item.validTo) : 'Not active',
            credits           : (item.credit - item.creditUsed) + ' / ' + item.credit,
            bruttoPrice       : item.bruttoPrice + ' ' + item.currency,
            paymentStatus     : item.paymentStatus
        };
    });

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleEditIconClick = (customerProductId) => {
        setIsUpdate(true)
        const customerProduct = getCustomerProduct(customerProductId);

        customerProduct
            .then((data) => {
                setCustomerProduct(data)
                setShowModal(true)
            })
            .catch((error) => console.log(error));
    }

    const handleCreateButtonClick = () => {
        setIsUpdate(false);
        setShowModal(true)
    }

    const handleCreateSubmit = (event) => {
        event.preventDefault();

        const customerId = selectCustomerRef.current.value;
        const productId = selectProductRef.current.value;
        const orderDate = orderDateRef.current.value;
        const paymentMethod = selectPaymentMethodRef.current.value;

        let activationType = null;

        if (product && product.productType === PRODUCT_TYPE_CREDIT_PASS) {
            activationType = selectActivationTypeRef.current.value;
        }

        createCustomerProduct(
            customerId,
            productId,
            orderDate,
            paymentMethod,
            activationType
        )
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();

        const credits = creditRef.current.value;
        const validTo = validToRef.current.value;

        updateCustomerProduct(
            customerProduct.customerProductId,
            credits,
            validTo
        )
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }

    const handleProductOnChange = (event) => {
        const productId = event.target.value;
        const product = getProduct(productId);
        product
            .then((data) => setProduct(data))
            .catch((error) => console.log(error));
    }

    const listHeaders = [
        'Id',
        'Customer name',
        'Product name',
        'Expiration date',
        'Credits',
        'Brutto price',
        'Status',
        'Actions'
    ];

    const columns = {
        functionParams : [
            'customerProductId'
        ],
        dataKeys       : [
            'customerProductId',
            'customerName',
            'productName',
            'validTo',
            'credits',
            'bruttoPrice',
            'paymentStatus'
        ],
    }

    const handleFirstNameFilterChange = () => {
        setPage(1);
        setFilterFirstName(firstNameFilterRef.current.value)
    }

    const handleLastNameFilterChange = () => {
        setPage(1);
        setFilterLastName(lastNameFilterRef.current.value)
    }

    return <Fragment>
        <Box>
            <Button title='Create customer product' handleClick={handleCreateButtonClick}/>
            <div className="flex my-4">
                <input ref={firstNameFilterRef} type="text" placeholder="Filter by first name" className="border p-2 mr-2" onChange={handleFirstNameFilterChange}/>
                <input ref={lastNameFilterRef} type="text" placeholder="Filter by last name" className="border p-2 mr-2" onChange={handleLastNameFilterChange}/>
                <select
                    id="customerId"
                    name="customerId"
                    onChange={(event) => {
                        const value = event.target.value === "" ? null : event.target.value;
                        setFilterCustomerId(value);
                    }}
                    className="border p-2 mr-2"
                >
                    <option value="">Filter by active customers</option>
                    {customers && customers.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.firstName} {option.lastName} {option.email ? `(${option.email})` : ''}
                        </option>
                    ))}
                </select>
            </div>
            <ListEdit
                headers={listHeaders}
                items={customerProductList}
                columns={columns}
                pagination={pagination}
                handleEditIconClick={handleEditIconClick}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
            />
        </Box>
        {showModal && (<Modal>
            <form onSubmit={isUpdate ? handleEditSubmit : handleCreateSubmit}>
                <ModalForm
                    title={isUpdate ? 'Update customer product' : 'Create customer product'}
                    saveButtonTitle={isUpdate ? 'Update' : 'Create'}
                    handleCancelButtonClick={() => setShowModal(false)}
                >
                    <ModalFormInputRow title="Customer">
                        {isUpdate ? (<input
                            type="text"
                            id="customer"
                            name="customer"
                            value={customerProduct.customerName}
                            className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            disabled
                        />) : customers && (<select
                            ref={selectCustomerRef}
                            className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                        >
                            <option value={null}>Select customer</option>
                            {customers.map((option) => (<option key={option.id} value={option.id}>
                                {option.firstName} {option.lastName} {option.email ? `(${option.email})` : ''}
                            </option>))}
                        </select>)}
                    </ModalFormInputRow>
                    <ModalFormInputRow title="Product">
                        {isUpdate ? (<input
                            type="text"
                            id="product"
                            name="product"
                            value={customerProduct.name}
                            className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            disabled
                        />) : products && (<select
                            ref={selectProductRef}
                            onChange={handleProductOnChange}
                            className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                        >
                            <option value={null}>Select product</option>
                            {products.map((option) => (<option key={option.productId} value={option.productId}>
                                {option.name}
                            </option>))}
                        </select>)}
                    </ModalFormInputRow>
                    {!isUpdate && (
                        <ModalFormInputRow title="Order date">
                            <input
                                ref={orderDateRef}
                                type="datetime-local"
                                id="orderDate"
                                name="orderDate"
                                defaultValue={new Date().toISOString().slice(0, 16)}
                                placeholder="Enter order date"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                    )}
                    {!isUpdate && (
                        <ModalFormInputRow title="Payment method">
                            {paymentMethods && (<select
                                ref={selectPaymentMethodRef}
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            >
                                {paymentMethods.map((option) => (<option key={option.paymentMethodId} value={option.name}>
                                    {option.name}
                                </option>))}
                            </select>)}
                        </ModalFormInputRow>
                    )}
                    {!isUpdate && product && product.productType === PRODUCT_TYPE_CREDIT_PASS && (
                        <ModalFormInputRow title="Activation type">
                            {productActivationTypes && (<select
                                ref={selectActivationTypeRef}
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            >
                                {productActivationTypes.map((option) => (<option key={option.productActivationTypeId} value={option.name}>
                                    {option.name}
                                </option>))}
                            </select>)}
                        </ModalFormInputRow>
                    )}
                    {isUpdate && (
                        <ModalFormInputRow title="Credits">
                            <input
                                ref={creditRef}
                                type="number"
                                id="credits"
                                name="credits"
                                defaultValue={isUpdate ? customerProduct.credit : null}
                                placeholder="Enter credits"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                    )}
                    {isUpdate && (
                        <ModalFormInputRow title="Valid to">
                            <input
                                ref={validToRef}
                                type="datetime-local"
                                id="validTo"
                                name="validTo"
                                defaultValue={isUpdate ? customerProduct.validTo : null}
                                placeholder="Enter valid to"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                    )}
                </ModalForm>
            </form>
        </Modal>)}
    </Fragment>;
}

export default CustomerProductList;