import {useCallback, useEffect, useState} from "react";
import {findCustomersWithPagination} from "../api/customerApiClient";

export const useCustomers = (page, pageSize, filterFirstName, filterLastName, filterStatus, filterEmailExistence, reloadTrigger) => {
    const [customers, setCustomers] = useState([]);
    const [pagination, setPagination] = useState(null);

    const fetchCustomers = useCallback(async () => {
        try {
            const data = await findCustomersWithPagination(page, pageSize, filterFirstName, filterLastName, filterStatus, filterEmailExistence);
            setCustomers(data.customers)
            setPagination(data.pagination)
        }
        catch (error) {
            console.log(error);
        }
    }, [filterFirstName, filterLastName, filterStatus, filterEmailExistence, page, pageSize, reloadTrigger]);

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers]);

    return {customers, pagination};
};