import {useCallback, useEffect, useState} from "react";
import {getEvent} from "../api/eventGroupApiClient";

export const useEvent = (eventId) => {
    const [event, setEvent] = useState(null);

    const fetchEvent = useCallback(async () => {
        try {
            const data = await getEvent(eventId);
            setEvent(data);
        }
        catch (error) {
            console.log(error);
        }
    }, [eventId]);

    useEffect(() => {
        fetchEvent();
    }, [fetchEvent]);

    return {event : event};
};