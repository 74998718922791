import {useEffect, useState} from "react";
import {findTrainersWithPagination} from "../api/eventGroupApiClient";

export const useTrainers = (page, pageSize, reloadTrigger) => {
    const [trainers, setTrainers] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const trainersWithPagination = findTrainersWithPagination(page, pageSize, abortController.signal);

        trainersWithPagination
            .then(
                (data) => {
                    const trainers = data.trainers;
                    const pagination = data.pagination;

                    setTrainers(trainers)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [page, pageSize, reloadTrigger]);

    return {trainers : trainers, pagination : pagination};
};