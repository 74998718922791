import {useEffect, useState} from "react";
import {findInvoicesWithPagination} from "../api/eventGroupApiClient";

export const useInvoices = (customerId, page, pageSize, reloadTrigger) => {
    const [invoices, setInvoices] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const invoicesWithPagination = findInvoicesWithPagination(page, pageSize, customerId, abortController.signal);

        invoicesWithPagination
            .then(
                (data) => {
                    const invoices = data.invoices;
                    const pagination = data.pagination;

                    setInvoices(invoices)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [customerId, page, pageSize, reloadTrigger]);

    return {invoices : invoices, pagination};
};