import {useEffect, useState} from "react";
import {findBookingGroupsAll} from "../api/eventGroupApiClient";

export const useBookingGroupsAll = (reloadTrigger) => {
    const [bookingGroups, setBookingGroups] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        const bookingGroupsWithPagination = findBookingGroupsAll(abortController.signal);

        bookingGroupsWithPagination
            .then(
                (data) => {
                    const bookingGroups = data;

                    setBookingGroups(bookingGroups)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [reloadTrigger]);

    return {bookingGroups : bookingGroups};
};