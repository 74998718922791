import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CustomQuillEditor = ({ value, onChange }) => {
    // Quill toolbar modules for specified functionality
    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }], // H1, H2, H3, normal text
            ["bold", "italic", "underline"], // Text formatting options
            [{ list: "ordered" }, { list: "bullet" }], // Numbered and bullet lists
        ],
    };

    const formats = [
        "header", // Headers (H1-H3)
        "bold", // Bold
        "italic", // Italic
        "underline", // Underline
        "list", // Ordered and bullet lists
    ];

    return (
        <ReactQuill
            value={value}
            onChange={onChange}
            modules={modules}
            formats={formats}
            placeholder="Enter your text here..."
            className="border rounded-lg w-full text-gray-700 focus:outline-none focus:shadow-outline"
        />
    );
};

export default CustomQuillEditor;
