import {useEffect, useState} from "react";
import {findEventGroupEvents} from "../api/eventGroupApiClient";

export const useEventGroupEvents = (eventGroupId, reloadTrigger) => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        console.log("useEventGroupEvents: eventGroupId=" + eventGroupId + " reloadTrigger=" + reloadTrigger);

        if (eventGroupId === null) {
            return;
        }

        const abortController = new AbortController();
        const eventTypes = findEventGroupEvents(eventGroupId, abortController.signal);

        eventTypes
            .then(
                (data) => {
                    const events = data;
                    setEvents(events)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [eventGroupId, reloadTrigger]);

    return {events : events};
};