import {Fragment, useRef, useState} from 'react';
import Button from "../UI/Button";
import React from "react";
import Modal from "../UI/Modal";
import {createBookingGroup, getBookingGroup, updateBookingGroup} from "../../api/eventGroupApiClient";
import ListEdit from "../UI/ListEdit";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import Box from "../UI/Box";
import {toast} from "react-toastify";
import {useBookingGroups} from "../../hooks/useBookingGroups";
import {useEventGroupsAll} from "../../hooks/useEventGroupsAll";
import {EVENT_TYPE_CLASS} from "../../utility/utility";

const BookingGroupList = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [bookingGroup, setBookingGroup] = useState(null);
    const [submitCount, setSubmitCount] = useState(0);
    const selectEventGroupsRef = useRef(null);
    const nameRef = useRef(null);
    const {bookingGroups, pagination} = useBookingGroups(page, pageSize, submitCount);
    const {eventGroups} = useEventGroupsAll(EVENT_TYPE_CLASS, 0);

    const bookingGroupList = bookingGroups.map((bookingGroup) => {
        return {
            bookingGroupId  : bookingGroup.bookingGroupId,
            name            : bookingGroup.name,
            eventGroupNames : bookingGroup.eventGroupNames.join(', ')
        }
    });

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleEditIconClick = (bookingGroupId) => {
        setIsUpdate(true);
        const bookingGroup = getBookingGroup(bookingGroupId);

        bookingGroup
            .then((data) => {
                setBookingGroup(data)
                setShowModal(true)
            })
            .catch((error) => console.log(error));
    }

    const handleCreateButtonClick = () => {
        setIsUpdate(false);
        setShowModal(true)
    }

    const handleCreateSubmit = (event) => {
        event.preventDefault();

        const name = nameRef.current.value;
        const eventGroups = Array.from(selectEventGroupsRef.current.selectedOptions).map(option => option.value);

        createBookingGroup(name, eventGroups)
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();

        const name = nameRef.current.value;
        const eventGroups = Array.from(selectEventGroupsRef.current.selectedOptions).map(option => option.value);

        updateBookingGroup(bookingGroup.bookingGroupId, name, eventGroups)
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }

    const listHeaders = ['Id', 'Name', 'Event groups', 'Actions'];

    const columns = {
        functionParams : ['bookingGroupId'], dataKeys : ['bookingGroupId', 'name', 'eventGroupNames'],
    }

    return <Fragment>
        <Box>
            <Button title='Create booking group' handleClick={handleCreateButtonClick}/>
            <ListEdit
                headers={listHeaders}
                items={bookingGroupList}
                columns={columns}
                pagination={pagination}
                handleEditIconClick={handleEditIconClick}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
            />
        </Box>
        {showModal && (<Modal>
            <form onSubmit={isUpdate ? handleEditSubmit : handleCreateSubmit}>
                <ModalForm
                    title={isUpdate ? 'Update booking group' : 'Create booking group'}
                    saveButtonTitle={isUpdate ? 'Update' : 'Create'}
                    handleCancelButtonClick={() => setShowModal(false)}
                >
                    <ModalFormInputRow title='Name'>
                        <input
                            ref={nameRef}
                            type="text"
                            id="name"
                            name="name"
                            defaultValue={isUpdate ? bookingGroup.name : null}
                            placeholder="Enter name"
                            className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                        />
                    </ModalFormInputRow>

                    <ModalFormInputRow title="Event groups">
                        {eventGroups && (!isUpdate || bookingGroup) && (
                            <select
                                defaultValue={isUpdate && bookingGroup.eventGroupIds ? bookingGroup.eventGroupIds.map(String) : []}
                                ref={selectEventGroupsRef}
                                multiple={true}
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            >
                                {eventGroups.map((option) => (
                                    <option key={option.eventGroupId} value={option.eventGroupId}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        )}
                    </ModalFormInputRow>


                </ModalForm>
            </form>
        </Modal>)}
    </Fragment>;
}

export default BookingGroupList;