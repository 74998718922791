import ListEdit from "../UI/ListEdit";
import {Link} from "react-router-dom";
import React, {Fragment, useRef, useState} from "react";
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import Box from "../UI/Box";
import {toast} from "react-toastify";
import {useEventGroup} from "../../hooks/useEventGroup";
import {useEventSeries} from "../../hooks/useEventSeries";
import {cancelEventSeries, createEventSeries, getEventSeries, updateEventSeries} from "../../api/eventGroupApiClient";
import {useTrainers} from "../../hooks/useTrainers";
import {convertToDisplayDateTime, EVENT_SERIES_STATUS_ACTIVE, EVENT_SERIES_STATUS_CANCELLED} from "../../utility/utility";

const EventSeriesList = (props) => {
    const {eventGroup} = useEventGroup(props.eventGroupId);
    const [showModal, setShowModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [submitCount, setSubmitCount] = useState(0);
    const [cancelEventSeriesId, setCancelEventSeriesId] = useState(null);
    const [filterStatus, setFilterStatus] = useState(EVENT_SERIES_STATUS_ACTIVE);
    const {eventSeriesListFromApi, pagination} = useEventSeries(props.eventGroupId, filterStatus, page, pageSize, submitCount);
    const {trainers} = useTrainers(1, 100, 0);
    const [eventSeries, setEventSeries] = useState(null);
    const startDateRef = useRef(null);
    const startTimeRef = useRef(null);
    const endTimeRef = useRef(null);
    const endDateRef = useRef(null);
    const mondayRef = useRef(null);
    const tuesdayRef = useRef(null);
    const wednesdayRef = useRef(null);
    const thursdayRef = useRef(null);
    const fridayRef = useRef(null);
    const saturdayRef = useRef(null);
    const sundayRef = useRef(null);
    const trainerSelectRef = useRef(null);
    const selectStatusFilterRef = useRef(null);

    const eventSeriesList = eventSeriesListFromApi.map((item) => {
        const trainerNames = item.trainers.map(function (trainer, index) {
            return trainer.displayName;
        }).join(', ');

        return {
            eventSeriesId : item.eventSeriesId,
            startDate     : item.startDate,
            startTime     : item.startTime,
            endTime       : item.endTime,
            endDate       : item.endDate,
            trainerNames  : trainerNames,
            monday        : item.monday ? 'Yes' : 'No',
            tuesday       : item.tuesday ? 'Yes' : 'No',
            wednesday     : item.wednesday ? 'Yes' : 'No',
            thursday      : item.thursday ? 'Yes' : 'No',
            friday        : item.friday ? 'Yes' : 'No',
            saturday      : item.saturday ? 'Yes' : 'No',
            sunday        : item.sunday ? 'Yes' : 'No',
            cancelledAt   : item.cancelledAt ? convertToDisplayDateTime(item.cancelledAt) : ''
        };
    });

    const listHeaders = [
        'Start date',
        'Start time',
        'End time',
        'End date',
        'Trainers',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat',
        'Sun',
        'Cancelled At'
    ];

    const columns = {
        functionParams : [
            'eventSeriesId'
        ],
        dataKeys       : [
            'startDate',
            'startTime',
            'endTime',
            'endDate',
            'trainerNames',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
            'sunday',
            'cancelledAt'
        ]
    }

    const handleCreateButtonClick = () => {
        setIsUpdate(false)
        setShowModal(true)
    }

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleAddSubmit = (event) => {
        event.preventDefault();

        const startDate = startDateRef.current.value;
        const startTime = startTimeRef.current.value;
        const endTime = endTimeRef.current.value;
        const endDate = endDateRef.current.value;
        const monday = mondayRef.current.checked ? 1 : 0;
        const tuesday = tuesdayRef.current.checked ? 1 : 0;
        const wednesday = wednesdayRef.current.checked ? 1 : 0;
        const thursday = thursdayRef.current.checked ? 1 : 0;
        const friday = fridayRef.current.checked ? 1 : 0;
        const saturday = saturdayRef.current.checked ? 1 : 0;
        const sunday = sundayRef.current.checked ? 1 : 0;
        const trainers = Array.from(trainerSelectRef.current.selectedOptions).map(option => option.value);

        createEventSeries(
            props.eventGroupId,
            startDate,
            startTime,
            endTime,
            trainers,
            endDate,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday
        )
            .then(() => {
                setShowModal(false);
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleEditIconClick = (eventSeriesId) => {
        setIsUpdate(true);
        const eventSeries = getEventSeries(eventSeriesId);

        eventSeries
            .then(
                (data) => {
                    setEventSeries(data)
                    setShowModal(true)
                }
            )
            .catch(
                (error) => console.log(error)
            );
    }

    const handleCancelIconClick = (eventSeriesId) => {
        // eslint-disable-next-line no-restricted-globals
        setShowCancelModal(true);
        setCancelEventSeriesId(eventSeriesId);
    }

    const handleCancelSubmit = (event) => {
        event.preventDefault();

        cancelEventSeries(cancelEventSeriesId)
            .then(() => {
                setShowCancelModal(false);
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();

        const endDate = endDateRef.current.value;
        const trainers = Array.from(trainerSelectRef.current.selectedOptions).map(option => option.value);

        updateEventSeries(
            eventSeries.eventSeriesId,
            endDate,
            trainers
        )
            .then(() => {
                setShowModal(false);
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleStatusFilterChange = () => {
        setFilterStatus(selectStatusFilterRef.current.value)
        setPage(1);
    }

    return (
        <Fragment>
            <Link to={'/event-groups'}>Back</Link>
            <Box>
                {eventGroup && <h1>Event group: {eventGroup.name}</h1>}
                <Button title='Add event series' handleClick={handleCreateButtonClick}/>
                <div className="flex my-4">
                    <select defaultValue={filterStatus} ref={selectStatusFilterRef} onChange={handleStatusFilterChange} className="border p-2 mr-2">
                        <option value="">Filter by status</option>
                        <option value={EVENT_SERIES_STATUS_ACTIVE}>Active</option>
                        <option value={EVENT_SERIES_STATUS_CANCELLED}>Cancelled</option>
                    </select>
                </div>
                <ListEdit
                    headers={listHeaders}
                    items={eventSeriesList}
                    columns={columns}
                    pagination={pagination}
                    handleEditIconClick={handleEditIconClick}
                    handleCancelIconClick={handleCancelIconClick}
                    handlePageChange={handlePageChange}
                    handlePageSizeChange={handlePageSizeChange}
                />
            </Box>
            {showCancelModal && (
                <Modal>
                    <form onSubmit={handleCancelSubmit}>
                        <ModalForm
                            title={'Are you sure you want to cancel this event series?'}
                            saveButtonTitle={'Confirm'}
                            handleCancelButtonClick={() => setShowCancelModal(false)}
                        >
                            <div className="flex flex-col">
                                <p className="text-sm text-gray-600">
                                    This action will:
                                </p>
                                <ul className="list-disc list-inside text-sm text-gray-600">
                                    <li>Mark the event series as cancelled.</li>
                                    <li>Cancel all future events linked to this series (they will not be deleted).</li>
                                    <li>Remove all bookings for these events.</li>
                                    <li>Refund the deducted credits to the customers' passes for these bookings.</li>
                                    <li>Send notification emails about the cancellation to all customers with bookings.</li>
                                </ul>
                            </div>
                        </ModalForm>
                    </form>
                </Modal>
            )}
            {showModal && (
                <Modal>
                    <form onSubmit={isUpdate ? handleEditSubmit : handleAddSubmit}>
                        <ModalForm
                            title={isUpdate ? 'Update event series' : 'Add event series'}
                            saveButtonTitle={isUpdate ? 'Update' : 'Add'}
                            handleCancelButtonClick={() => setShowModal(false)}
                        >
                            {!isUpdate && <ModalFormInputRow title='Start date'>
                                <input
                                    ref={startDateRef}
                                    type="date"
                                    id="startDate"
                                    name="startDate"
                                    defaultValue={isUpdate ? eventSeries.startDate : null}
                                    placeholder="Enter start date"
                                    className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                />
                            </ModalFormInputRow>}
                            {!isUpdate && <ModalFormInputRow title='Start time'>
                                <input
                                    ref={startTimeRef}
                                    type="time"
                                    id="startTime"
                                    name="startTime"
                                    defaultValue={isUpdate ? eventSeries.startTime : null}
                                    placeholder="Enter start time"
                                    className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                />
                            </ModalFormInputRow>}
                            {!isUpdate && <ModalFormInputRow title='End time'>
                                <input
                                    ref={endTimeRef}
                                    type="time"
                                    id="endTime"
                                    name="endTime"
                                    defaultValue={isUpdate ? eventSeries.endTime : null}
                                    placeholder="Enter end time"
                                    className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                />
                            </ModalFormInputRow>}
                            <ModalFormInputRow title='End date'>
                                <input
                                    ref={endDateRef}
                                    type="date"
                                    id="endDate"
                                    name="endDate"
                                    defaultValue={isUpdate ? eventSeries.endDate : null}
                                    placeholder="Enter end date"
                                    className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                />
                            </ModalFormInputRow>
                            <ModalFormInputRow title='Trainers'>
                                {trainers && (!isUpdate || eventSeries) && <select defaultValue={isUpdate ? eventSeries.trainers.map(obj => obj.trainerId) : null} ref={trainerSelectRef} multiple={true} className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline">
                                    {
                                        trainers.map(
                                            (option) => (
                                                <option key={option.trainerId} value={option.trainerId}>{option.displayName}</option>
                                            )
                                        )
                                    }
                                </select>}
                            </ModalFormInputRow>
                            {!isUpdate && <ModalFormInputRow title='Mon'>
                                <input
                                    ref={mondayRef}
                                    type="checkbox"
                                    id="monday"
                                    name="monday"
                                    defaultChecked={isUpdate ? eventSeries.monday : null}
                                    className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                />
                            </ModalFormInputRow>}
                            {!isUpdate && <ModalFormInputRow title='Tue'>
                                <input
                                    ref={tuesdayRef}
                                    type="checkbox"
                                    id="tuesday"
                                    name="tuesday"
                                    defaultChecked={isUpdate ? eventSeries.tuesday : null}
                                    className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                />
                            </ModalFormInputRow>}
                            {!isUpdate && <ModalFormInputRow title='Wed'>
                                <input
                                    ref={wednesdayRef}
                                    type="checkbox"
                                    id="wednesday"
                                    name="wednesday"
                                    defaultChecked={isUpdate ? eventSeries.wednesday : null}
                                    className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                />
                            </ModalFormInputRow>}
                            {!isUpdate && <ModalFormInputRow title='Thu'>
                                <input
                                    ref={thursdayRef}
                                    type="checkbox"
                                    id="thursday"
                                    name="thursday"
                                    defaultChecked={isUpdate ? eventSeries.thursday : null}
                                    className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                />
                            </ModalFormInputRow>}
                            {!isUpdate && <ModalFormInputRow title='Fri'>
                                <input
                                    ref={fridayRef}
                                    type="checkbox"
                                    id="friday"
                                    name="friday"
                                    defaultChecked={isUpdate ? eventSeries.friday : null}
                                    className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                />
                            </ModalFormInputRow>}
                            {!isUpdate && <ModalFormInputRow title='Sat'>
                                <input
                                    ref={saturdayRef}
                                    type="checkbox"
                                    id="saturday"
                                    name="saturday"
                                    defaultChecked={isUpdate ? eventSeries.saturday : null}
                                    className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                />
                            </ModalFormInputRow>}
                            {!isUpdate && <ModalFormInputRow title='Sun'>
                                <input
                                    ref={sundayRef}
                                    type="checkbox"
                                    id="sunday"
                                    name="sunday"
                                    defaultChecked={isUpdate ? eventSeries.sunday : null}
                                    className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                />
                            </ModalFormInputRow>}
                        </ModalForm>
                    </form>
                </Modal>
            )}
        </Fragment>

    );
};

export default EventSeriesList;