import {useEffect, useState} from "react";
import {findPaymentMethods} from "../api/eventGroupApiClient";

export const usePaymentMethods = (onlyManual, reloadTrigger) => {
    const [paymentMethods, setPaymentMethods] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        const paymentMethods = findPaymentMethods(onlyManual, abortController.signal);

        paymentMethods
            .then(
                (data) => {
                    const paymentMethods = data;

                    setPaymentMethods(paymentMethods)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [onlyManual, reloadTrigger]);

    return {paymentMethods : paymentMethods};
};