import {useEffect, useState} from "react";
import {findEventGroups} from "../api/eventGroupApiClient";

export const useEventGroupsAll = (eventType, reloadTrigger) => {
    const [eventGroups, setEventGroups] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        const eventGroups = findEventGroups(eventType, abortController.signal);

        eventGroups
            .then(
                (data) => {
                    const eventGroups = data;

                    setEventGroups(eventGroups)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [eventType, reloadTrigger]);

    return {eventGroups : eventGroups};
};