import {useEffect, useState} from "react";
import {findBookingGroupsWithPagination} from "../api/eventGroupApiClient";

export const useBookingGroups = (page, pageSize, reloadTrigger) => {
    const [bookingGroups, setBookingGroups] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const bookingGroupsWithPagination = findBookingGroupsWithPagination(page, pageSize, abortController.signal);

        bookingGroupsWithPagination
            .then(
                (data) => {
                    const bookingGroups = data.bookingGroups;
                    const pagination = data.pagination;

                    setBookingGroups(bookingGroups)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [page, pageSize, reloadTrigger]);

    return {bookingGroups : bookingGroups, pagination};
};