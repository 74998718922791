import {useEffect, useState} from "react";
import {findProductsWithPagination} from "../api/eventGroupApiClient";

export const useProducts = (filterStatus, page, pageSize, reloadTrigger) => {
    const [products, setProducts] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const productsWithPagination = findProductsWithPagination(page, pageSize, filterStatus, abortController.signal);

        productsWithPagination
            .then(
                (data) => {
                    const products = data.products;
                    const pagination = data.pagination;

                    setProducts(products)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [filterStatus, page, pageSize, reloadTrigger]);

    return {products : products, pagination};
};