import React, {Fragment, useRef, useState} from 'react';
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import {createEventGroup, getEventGroup, updateEventGroup} from "../../api/eventGroupApiClient";
import ListEdit from "../UI/ListEdit";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import Box from "../UI/Box";
import {toast} from "react-toastify";
import {useEventGroups} from "../../hooks/useEventGroups";
import {useEventTypes} from "../../hooks/useEventTypes";
import {useLocations} from "../../hooks/useLocations";
import {useNavigate} from "react-router-dom";
import CustomQuillEditor from "../UI/CustomQuillEditor";
import {TwitterPicker} from "react-color";
import {EVENT_GROUP_STATUS_ACTIVE, EVENT_GROUP_STATUS_ARCHIVED} from "../../utility/utility";

const EventGroupList = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [eventGroup, setEventGroup] = useState(null);
    const [submitCount, setSubmitCount] = useState(0);
    const [description, setDescription] = useState(""); // React Quill state
    const [color, setColor] = useState("#ffffff"); // State for color picker
    const [filterStatus, setFilterStatus] = useState(EVENT_GROUP_STATUS_ACTIVE);
    const selectEventTypeRef = useRef(null);
    const locationIdRef = useRef(null);
    const nameRef = useRef(null);
    const seatsRef = useRef(null);
    const cancellationCreditRefundPeriodRef = useRef(null);
    const imageRef = useRef(null);
    const selectStatusFilterRef = useRef(null);
    const {eventGroups, pagination} = useEventGroups(filterStatus, page, pageSize, submitCount);
    const {locations} = useLocations(1, 100, 0);
    const {eventTypes} = useEventTypes(0);
    const navigate = useNavigate();

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleEditIconClick = (eventGroupId) => {
        setIsUpdate(true);
        const eventGroup = getEventGroup(eventGroupId);

        eventGroup
            .then(
                (data) => {
                    setEventGroup(data)
                    setDescription(data.description); // Set the description state
                    setColor(data.color || "#ffffff");
                    setShowModal(true)
                }
            )
            .catch(
                (error) => console.log(error)
            );
    }

    const handleShowIconClick = (eventGroupId) => {
        navigate('/event-groups/' + eventGroupId + '/event-series');
    }

    const handleCreateButtonClick = () => {
        setIsUpdate(false);
        setColor("#ffffff"); // Reset color picker
        setShowModal(true)
    }

    const handleCreateSubmit = (event) => {
        event.preventDefault();

        const selectedEventType = selectEventTypeRef.current.value === '0' ? null : selectEventTypeRef.current.value;
        const locationId = locationIdRef.current.value;
        const name = nameRef.current.value;
        const seats = seatsRef.current.value;
        const cancellationCreditRefundPeriod = cancellationCreditRefundPeriodRef.current.value;
        const image = imageRef.current.files[0] ?? null;

        createEventGroup(
            selectedEventType,
            locationId,
            name,
            seats,
            color, // Use selected color
            cancellationCreditRefundPeriod,
            description, // Use React Quill content
            image
        )
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();

        const locationId = locationIdRef.current.value;
        const name = nameRef.current.value;
        const seats = seatsRef.current.value;
        const cancellationCreditRefundPeriod = cancellationCreditRefundPeriodRef.current.value;
        const image = imageRef.current.files[0] ?? null;

        updateEventGroup(
            eventGroup.eventGroupId,
            locationId,
            name,
            seats,
            color, // Use selected color
            description, // Use React Quill content
            cancellationCreditRefundPeriod,
            image
        )
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleStatusFilterChange = () => {
        setFilterStatus(selectStatusFilterRef.current.value)
        setPage(1);
    }

    const listHeaders = [
        'Id',
        'Name',
        'Event Type',
        'Seats',
        'Color',
        'Location',
        'Actions'
    ];

    const columns = {
        functionParams : ['eventGroupId'],
        dataKeys       : [
            'eventGroupId',
            'name',
            'eventTypeName',
            'seats',
            'color',
            'locationName'
        ],
    }

    return <Fragment>
        <Box>
            <Button title='Create event' handleClick={handleCreateButtonClick}/>
            <div className="flex my-4">
                <select defaultValue={filterStatus} ref={selectStatusFilterRef} onChange={handleStatusFilterChange} className="border p-2 mr-2">
                    <option value="">Filter by status</option>
                    <option value={EVENT_GROUP_STATUS_ACTIVE}>Active</option>
                    <option value={EVENT_GROUP_STATUS_ARCHIVED}>Archived</option>
                </select>
            </div>
            <ListEdit
                headers={listHeaders}
                items={eventGroups}
                columns={columns}
                pagination={pagination}
                handleEditIconClick={handleEditIconClick}
                handleShowIconClick={handleShowIconClick}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
            />
        </Box>
        {showModal && (
            <Modal>
                <form onSubmit={isUpdate ? handleEditSubmit : handleCreateSubmit}>
                    <ModalForm
                        title={isUpdate ? 'Update event' : 'Create event'}
                        saveButtonTitle={isUpdate ? 'Update' : 'Create'}
                        handleCancelButtonClick={() => setShowModal(false)}
                    >
                        <ModalFormInputRow title="Event type">
                            {eventTypes && (!isUpdate || eventGroup) && (
                                <select
                                    defaultValue={isUpdate && eventGroup ? eventGroup.name : ''}
                                    ref={selectEventTypeRef}
                                    className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                >
                                    {eventTypes.map((option) => (
                                        <option key={option.eventTypeId} value={option.name}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </ModalFormInputRow>

                        <ModalFormInputRow title='Name'>
                            <input
                                ref={nameRef}
                                type="text"
                                id="name"
                                name="name"
                                defaultValue={isUpdate ? eventGroup.name : null}
                                placeholder="Enter name"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                        <ModalFormInputRow title='Seats'>
                            <input
                                ref={seatsRef}
                                type="number"
                                id="seats"
                                name="seats"
                                defaultValue={isUpdate ? eventGroup.seats : null}
                                placeholder="Enter seats"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                        <ModalFormInputRow title="Color">
                            <TwitterPicker
                                color={color}
                                onChangeComplete={(newColor) => setColor(newColor.hex)}
                            />
                        </ModalFormInputRow>
                        <ModalFormInputRow title="Location">
                            {locations && (!isUpdate || eventGroup) && (
                                <select
                                    defaultValue={isUpdate && eventGroup ? eventGroup.locationId : 0}
                                    ref={locationIdRef}
                                    className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                >
                                    {locations.map((option) => (
                                        <option key={option.locationId} value={option.locationId}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </ModalFormInputRow>
                        <ModalFormInputRow title="Description">
                            <CustomQuillEditor
                                value={description}
                                onChange={setDescription}
                            />
                        </ModalFormInputRow>
                        <ModalFormInputRow title='Cancellation credit refund period (hours)'>
                            <input
                                ref={cancellationCreditRefundPeriodRef}
                                type="number"
                                id="cancellationCreditRefundPeriod"
                                name="cancellationCreditRefundPeriod"
                                defaultValue={isUpdate ? eventGroup.cancellationCreditRefundPeriod : null}
                                placeholder="Enter cancellation credit refund period"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                        <ModalFormInputRow title="Image Upload">
                            <input
                                type="file"
                                id="image"
                                name="image"
                                accept="image/*"
                                ref={imageRef}
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                            {isUpdate && eventGroup?.image && (
                                <div className="mt-2">
                                    <img
                                        src={eventGroup.image}
                                        alt="Preview"
                                        className="w-24 h-24 object-cover border rounded"
                                    />
                                </div>
                            )}
                        </ModalFormInputRow>
                    </ModalForm>
                </form>
            </Modal>
        )}
    </Fragment>;
}

export default EventGroupList;