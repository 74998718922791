import {useCallback, useEffect, useState} from "react";
import {getEventGroup} from "../api/eventGroupApiClient";

export const useEventGroup = (eventGroupId) => {
    const [eventGroup, setEventGroup] = useState(null);

    const fetchEventGroup = useCallback(async () => {
        try {
            const data = await getEventGroup(eventGroupId);
            setEventGroup(data);
        }
        catch (error) {
            console.log(error);
        }
    }, [eventGroupId]);

    useEffect(() => {
        fetchEventGroup();
    }, [fetchEventGroup]);

    return {eventGroup : eventGroup};
};