import {useEffect, useState} from "react";
import {findProductTypes} from "../api/eventGroupApiClient";

export const useProductTypes = (reloadTrigger) => {
    const [productTypes, setProductTypes] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        const productTypes = findProductTypes(abortController.signal);

        productTypes
            .then(
                (data) => {
                    const productTypes = data;

                    setProductTypes(productTypes)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [reloadTrigger]);

    return {productTypes : productTypes};
};