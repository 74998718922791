import {useEffect, useState} from "react";
import {findPayableOrders} from "../api/eventGroupApiClient";

export const usePayableOrders = (customerId, reloadTrigger) => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        if (!customerId) {
            return;
        }

        const abortController = new AbortController();
        const payableOrders = findPayableOrders(customerId, abortController.signal);

        payableOrders
            .then(
                (data) => {
                    const orders = data;

                    setOrders(orders)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [customerId, reloadTrigger]);

    return {orders : orders};
};