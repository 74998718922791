import {useEffect, useState} from "react";
import {findProductActivationTypes} from "../api/eventGroupApiClient";

export const useProductActivationTypes = (reloadTrigger) => {
    const [productActivationTypes, setProductActivationTypes] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        const productActivationTypes = findProductActivationTypes(abortController.signal);

        productActivationTypes
            .then(
                (data) => {
                    const productActivationTypes = data;

                    setProductActivationTypes(productActivationTypes)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [reloadTrigger]);

    return {productActivationTypes : productActivationTypes};
};