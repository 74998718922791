import {Fragment, useRef, useState} from 'react';
import Button from "../UI/Button";
import React from "react";
import Modal from "../UI/Modal";
import {createProduct, deleteProduct, getProduct, updateProduct} from "../../api/eventGroupApiClient";
import ListEdit from "../UI/ListEdit";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import Box from "../UI/Box";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useProducts} from "../../hooks/useProducts";
import {useProductTypes} from "../../hooks/useProductTypes";
import {useProductActivationTypes} from "../../hooks/useProductActivationTypes";
import {useProductValidityUnits} from "../../hooks/useProductValidityUnits";
import {useEventGroupsAll} from "../../hooks/useEventGroupsAll";
import {EVENT_TYPE_SPECIAL_EVENT, formatEventDate, PRODUCT_STATUS_ACTIVE, PRODUCT_STATUS_ARCHIVED, PRODUCT_TYPE_CREDIT_PASS} from "../../utility/utility";
import {useEventGroupEvents} from "../../hooks/useEventsByGroupId";
import {useBookingGroupsAll} from "../../hooks/useBookingGroupsAll";
import CustomQuillEditor from "../UI/CustomQuillEditor";

const ProductList = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [productType, setProductType] = useState(PRODUCT_TYPE_CREDIT_PASS);
    const [eventGroupId, setEventGroupId] = useState(0);
    const [eventIds, setEventIds] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [product, setProduct] = useState(null);
    const [submitCount, setSubmitCount] = useState(0);
    const [description, setDescription] = useState(""); // React Quill state
    const [filterStatus, setFilterStatus] = useState(PRODUCT_STATUS_ACTIVE);
    const selectProductTypeRef = useRef(null);
    const selectProductActivationTypeRef = useRef(null);
    const selectProductValidityUnitRef = useRef(null);
    const selectEventGroupsRef = useRef(null);
    const selectEventsRef = useRef(null);
    const selectBookingGroupsRef = useRef(null);
    const nameRef = useRef(null);
    const bruttoPriceRef = useRef(null);
    const creditRef = useRef(null);
    const validityRef = useRef(null);
    const isRestrictedOnePerUserRef = useRef(null);
    const isPublicRef = useRef(null);
    const isShopVisibleAfterStartRef = useRef(null);
    const orderRef = useRef(null);
    const selectStatusFilterRef = useRef(null);
    const {products, pagination} = useProducts(filterStatus, page, pageSize, submitCount);
    const {productTypes} = useProductTypes(0);
    const {eventGroups} = useEventGroupsAll(EVENT_TYPE_SPECIAL_EVENT, 0);
    const {events} = useEventGroupEvents(eventGroupId, 0);
    const {productActivationTypes} = useProductActivationTypes(0);
    const {productValidityUnits} = useProductValidityUnits(0);
    const {bookingGroups} = useBookingGroupsAll(0);

    const navigate = useNavigate();

    const productList = products.map((item) => {
        return {
            productId   : item.productId,
            name        : item.name,
            productType : item.productType,
            bruttoPrice : item.bruttoPrice + ' ' + item.currency,
            isPublic    : item.isPublic ? 'Yes' : 'No',
        };
    });

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleEditIconClick = (productId) => {
        setIsUpdate(true)
        const product = getProduct(productId);

        product
            .then(
                (data) => {
                    setProduct(data)
                    setDescription(data.description); // Set the description state
                    setProductType(data.productType)
                    if (data.eventGroup) {
                        setEventGroupId(data.eventGroup.eventGroupId)
                        setEventIds(data.eventGroup.eventIds)
                    }
                    setShowModal(true)
                }
            )
            .catch(
                (error) => console.log(error)
            );
    }

    const handleCreateButtonClick = () => {
        setIsUpdate(false);
        setShowModal(true)
    }

    const handleCreateSubmit = (event) => {
        event.preventDefault();

        let selectProductActivationType = null;
        let selectProductValidityUnit = null;
        let isShopVisibleAfterStart = null;
        let credit = null;
        let validity = null;
        let eventGroupId = null;
        let eventIds = null;
        let bookingGroupIds = null;

        if (selectProductActivationTypeRef && selectProductActivationTypeRef.current) {
            selectProductActivationType = selectProductActivationTypeRef.current.value === '0' ? null : selectProductActivationTypeRef.current.value;
        }

        if (selectProductValidityUnitRef && selectProductValidityUnitRef.current) {
            selectProductValidityUnit = selectProductValidityUnitRef.current.value === '0' ? null : selectProductValidityUnitRef.current.value;
        }

        if (isShopVisibleAfterStartRef && isShopVisibleAfterStartRef.current) {
            isShopVisibleAfterStart = isShopVisibleAfterStartRef.current.checked ? 1 : 0;
        }

        if (creditRef && creditRef.current) {
            credit = creditRef.current.value;
        }

        if (validityRef && validityRef.current) {
            validity = validityRef.current.value;
        }

        if (selectEventGroupsRef && selectEventGroupsRef.current) {
            eventGroupId = selectEventGroupsRef.current.value;
        }

        if (selectEventsRef && selectEventsRef.current) {
            eventIds = Array.from(selectEventsRef.current.selectedOptions).map(option => option.value);
        }

        if (selectBookingGroupsRef && selectBookingGroupsRef.current) {
            bookingGroupIds = Array.from(selectBookingGroupsRef.current.selectedOptions).map(option => option.value);
        }

        const productTypeInput = selectProductTypeRef.current.value;
        const name = nameRef.current.value;
        const bruttoPrice = bruttoPriceRef.current.value;
        const isRestrictedOnePerUser = isRestrictedOnePerUserRef.current.checked ? 1 : 0;
        const isPublic = isPublicRef.current.checked ? 1 : 0;
        const order = orderRef.current.value || 1;

        createProduct(
            productTypeInput,
            name,
            description, // Use React Quill content
            bruttoPrice,
            isRestrictedOnePerUser,
            isPublic,
            order,
            isShopVisibleAfterStart,
            credit,
            validity,
            selectProductActivationType,
            selectProductValidityUnit,
            eventGroupId,
            eventIds,
            bookingGroupIds
        )
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();

        let selectProductActivationType = null;
        let selectProductValidityUnit = null;
        let isShopVisibleAfterStart = null;
        let credit = null;
        let validity = null;
        let eventGroupId = null;
        let eventIds = null;
        let bookingGroupIds = null;

        if (selectProductActivationTypeRef && selectProductActivationTypeRef.current) {
            selectProductActivationType = selectProductActivationTypeRef.current.value === '' ? null : selectProductActivationTypeRef.current.value;
        }

        if (selectProductValidityUnitRef && selectProductValidityUnitRef.current) {
            selectProductValidityUnit = selectProductValidityUnitRef.current.value === '' ? null : selectProductValidityUnitRef.current.value;
        }

        if (isShopVisibleAfterStartRef && isShopVisibleAfterStartRef.current) {
            isShopVisibleAfterStart = isShopVisibleAfterStartRef.current.checked ? 1 : 0;
        }

        if (creditRef && creditRef.current) {
            credit = creditRef.current.value;
        }

        if (validityRef && validityRef.current) {
            validity = validityRef.current.value;
        }

        if (selectEventGroupsRef && selectEventGroupsRef.current) {
            eventGroupId = selectEventGroupsRef.current.value;
        }

        if (selectEventsRef && selectEventsRef.current) {
            eventIds = Array.from(selectEventsRef.current.selectedOptions).map(option => option.value);
        }

        if (selectBookingGroupsRef && selectBookingGroupsRef.current) {
            bookingGroupIds = Array.from(selectBookingGroupsRef.current.selectedOptions).map(option => option.value);
        }

        const name = nameRef.current.value;
        const bruttoPrice = bruttoPriceRef.current.value;
        const isRestrictedOnePerUser = isRestrictedOnePerUserRef.current.checked ? 1 : 0;
        const isPublic = isPublicRef.current.checked ? 1 : 0;
        const order = orderRef.current.value || 1;

        updateProduct(
            product.productId,
            name,
            description, // Use React Quill content
            bruttoPrice,
            isRestrictedOnePerUser,
            isPublic,
            order,
            isShopVisibleAfterStart,
            credit,
            validity,
            selectProductActivationType,
            selectProductValidityUnit,
            eventGroupId,
            eventIds,
            bookingGroupIds
        )
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const listHeaders = [
        'Id',
        'Name',
        'Type',
        'Amount',
        'Is online',
        'Actions'
    ];

    const columns = {
        functionParams : ['productId'],
        dataKeys       : [
            'productId',
            'name',
            'productType',
            'bruttoPrice',
            'isPublic',
        ],
    }

    const handleEventGroupChange = () => {
        setProductType(selectProductTypeRef.current.value);
        setEventGroupId(0);
    }

    const handleStatusFilterChange = () => {
        setFilterStatus(selectStatusFilterRef.current.value)
        setPage(1);
    }

    const handleDeleteIconClick = (productId) => {
        if (window.confirm('Are you sure you want to archive this product?')) {
            deleteProduct(productId).then(
                () => {
                    setSubmitCount(prevCount => prevCount + 1);
                    toast.success('Success!');
                }
            ).catch((error) => {
                toast.error(error.message);
            });
        }
    }

    return <Fragment>
        <Box>
            <Button title='Create product' handleClick={handleCreateButtonClick}/>
            <div className="flex my-4">
                <select defaultValue={filterStatus} ref={selectStatusFilterRef} onChange={handleStatusFilterChange} className="border p-2 mr-2">
                    <option value="">Filter by status</option>
                    <option value={PRODUCT_STATUS_ACTIVE}>Active</option>
                    <option value={PRODUCT_STATUS_ARCHIVED}>Archived</option>
                </select>
            </div>
            <ListEdit
                headers={listHeaders}
                items={productList}
                columns={columns}
                pagination={pagination}
                handleEditIconClick={handleEditIconClick}
                handleDeleteIconClick={handleDeleteIconClick}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
            />
        </Box>
        {showModal && (
            <Modal>
                <form onSubmit={isUpdate ? handleEditSubmit : handleCreateSubmit}>
                    <ModalForm
                        title={isUpdate ? 'Update product' : 'Create product'}
                        saveButtonTitle={isUpdate ? 'Update' : 'Create'}
                        handleCancelButtonClick={() => setShowModal(false)}
                    >
                        {<ModalFormInputRow title="Product type">
                            {productTypes && (!isUpdate || product) && (
                                <select
                                    defaultValue={productType}
                                    ref={selectProductTypeRef}
                                    className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                    onChange={handleEventGroupChange}
                                >
                                    {productTypes.map((option) => (
                                        <option key={option.productTypeId} value={option.name}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </ModalFormInputRow>}
                        <ModalFormInputRow title='Name'>
                            <input
                                ref={nameRef}
                                type="text"
                                id="name"
                                name="name"
                                defaultValue={isUpdate ? product.name : null}
                                placeholder="Enter name"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>

                        <ModalFormInputRow title="Description">
                            <CustomQuillEditor
                                value={description}
                                onChange={setDescription}
                            />
                        </ModalFormInputRow>
                        <ModalFormInputRow title='Brutto price'>
                            <input
                                ref={bruttoPriceRef}
                                type="number"
                                id="bruttoPrice"
                                name="bruttoPrice"
                                defaultValue={isUpdate ? product.bruttoPrice : null}
                                placeholder="Enter brutto price"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                        {productType === 'credit_pass' && (
                            <Fragment>
                                <ModalFormInputRow title='Number of units'>
                                    <input
                                        ref={creditRef}
                                        type="number"
                                        id="credit"
                                        name="credit"
                                        defaultValue={isUpdate ? product.credit : null}
                                        placeholder="Enter credit"
                                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                    />
                                </ModalFormInputRow>
                                <ModalFormInputRow title='Validity'>
                                    <input
                                        ref={validityRef}
                                        type="number"
                                        id="validity"
                                        name="validity"
                                        defaultValue={isUpdate ? product.validity : null}
                                        placeholder="Enter validity"
                                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                    />
                                </ModalFormInputRow>
                                <ModalFormInputRow title="Validity unit">
                                    {productValidityUnits && (
                                        <select
                                            defaultValue={isUpdate ? product.validityUnit : ""}
                                            ref={selectProductValidityUnitRef}
                                            className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                        >
                                            {productValidityUnits.map((option) => (
                                                <option key={option.validityUnitId} value={option.name}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </ModalFormInputRow>
                                <ModalFormInputRow title="Activation type">
                                    {productActivationTypes && (
                                        <select
                                            defaultValue={isUpdate ? product.activationType : ""}
                                            ref={selectProductActivationTypeRef}
                                            className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                        >
                                            {productActivationTypes.map((option) => (
                                                <option key={option.productActivationTypeId} value={option.name}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </ModalFormInputRow>
                                <ModalFormInputRow title="Booking groups">
                                    {bookingGroups && (!isUpdate || product) && (
                                        <select
                                            ref={selectBookingGroupsRef}
                                            multiple={true}
                                            className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                            defaultValue={isUpdate ? product.bookingGroupIds : null}
                                        >
                                            {bookingGroups.map((option) => (
                                                <option key={option.bookingGroupId} value={option.bookingGroupId}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </ModalFormInputRow>
                            </Fragment>
                        )}
                        {productType === 'event_ticket' && (
                            <Fragment>
                                <ModalFormInputRow title="Event groups">
                                    {eventGroups && (!isUpdate || product) && (
                                        <select
                                            defaultValue={isUpdate ? eventGroupId : 0}
                                            ref={selectEventGroupsRef}
                                            className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                            onChange={(event) => setEventGroupId(event.target.value)}
                                        >
                                            <option key={0} value={0}>No event group</option>
                                            {eventGroups.map((option) => (
                                                <option key={option.eventGroupId} value={option.eventGroupId}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </ModalFormInputRow>
                                <ModalFormInputRow title="Events">
                                    {events && (
                                        <select
                                            ref={selectEventsRef}
                                            multiple={true}
                                            className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                            value={eventIds || []} // Ensure eventIds is always an array
                                            onChange={(e) => {
                                                const selectedValues = Array.from(e.target.selectedOptions).map(option => option.value);
                                                setEventIds(selectedValues); // Update state with selected values
                                            }}
                                        >
                                            {events.map((option) => (
                                                <option key={option.eventId} value={option.eventId}>
                                                    {formatEventDate(option.startedAt, option.endedAt)}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </ModalFormInputRow>
                            </Fragment>
                        )}
                        <ModalFormInputRow title='Is public'>
                            <input
                                ref={isPublicRef}
                                type="checkbox"
                                id="isPublic"
                                name="isPublic"
                                defaultChecked={isUpdate ? product.isPublic : null}
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                        <ModalFormInputRow title='Is restricted one per user'>
                            <input
                                ref={isRestrictedOnePerUserRef}
                                type="checkbox"
                                id="isRestrictedOnePerUser"
                                name="isRestrictedOnePerUser"
                                defaultChecked={isUpdate ? product.isRestrictedOnePerUser : null}
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                        {productType === 'event_ticket' && (<ModalFormInputRow title='Is shop visible after start'>
                            <input
                                ref={isShopVisibleAfterStartRef}
                                type="checkbox"
                                id="isShopVisibleAfterStart"
                                name="isShopVisibleAfterStart"
                                defaultChecked={isUpdate ? product.isShopVisibleAfterStart : null}
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>)}
                        <ModalFormInputRow title='Order'>
                            <input
                                ref={orderRef}
                                type="number"
                                id="order"
                                name="order"
                                defaultValue={isUpdate ? product.order : null}
                                placeholder="Enter order"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                    </ModalForm>
                </form>
            </Modal>
        )}
    </Fragment>;
}

export default ProductList;