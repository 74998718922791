import React from "react";
import {useParams} from 'react-router-dom';
import EventBookingList from "../../components/event/EventBookingList";

const EventBookingsPage = () => {
    const {eventId} = useParams();

    return <EventBookingList eventId={eventId}/>
}

export default EventBookingsPage;