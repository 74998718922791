import React, {Fragment, useState} from "react";
import CustomerFigureModal from "../../components/customer/CustomerFigureModal";
import {findCustomerNotAssignedFiguresWithPagination, getCustomerFigure} from "../../api/customerApiClient";
import Box from "../../components/UI/Box";
import {useCustomers} from "../../hooks/useCustomers";
import {useThematics} from "../../hooks/useThematics";
import {getCustomerFigureStatuses} from "../../utility/utility";
import FigureProgressTrackerTable from "../figureProgressTracker/FigureProgressTrackerTable";

const CustomerThematicFigureList = () => {
    const [customerFigure, setCustomerFigure] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showComments, setShowComments] = useState(true);
    const [submitCount, setSubmitCount] = useState(0);
    const [figures, setFigures] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false);
    const [defaultModalFigureValue, setDefaultModalFigureValue] = useState(null);
    const [defaultModalStatusValue, setDefaultModalStatusValue] = useState(null);
    const {customers} = useCustomers(1, 100, null, null, 'active', null, 0);
    const {thematics} = useThematics(1, 100, 0);
    const [statuses] = useState(getCustomerFigureStatuses());
    const [customerFilters, setCustomerFilters] = useState([]);
    const [thematicFilters, setThematicFilters] = useState([]);
    const [statusFilters, setStatusFilters] = useState([]);
    const [customerIdToEdit, setCustomerIdToEdit] = useState(null);

    const handleAddSubmit = (event) => {
        setShowModal(false)
        setSubmitCount(prevCount => prevCount + 1);
    }

    const handleEditSubmit = (event) => {
        setShowModal(false)
        setSubmitCount(prevCount => prevCount + 1);
    }

    const handleAddFigureButtonClick = (customerId) => {
        setIsUpdate(false);
        const figuresWithPagination = findCustomerNotAssignedFiguresWithPagination(customerId);

        figuresWithPagination
            .then(
                (data) => {
                    const figures = data.figures;

                    setFigures(figures)
                    setShowModal(true)
                }
            )
            .catch(
                (error) => console.log(error)
            );

    }

    const handleStatusButtonIconClick = (customerId, figureId, status) => {
        setIsUpdate(true);
        const customerFigure = getCustomerFigure(customerId, figureId);

        customerFigure
            .then(
                (data) => {
                    setCustomerFigure(data)
                    setDefaultModalStatusValue(status)
                    setShowModal(true)
                }
            )
            .catch(
                (error) => {
                    if (error.status === 400 && error.code === 101) {
                        setDefaultModalFigureValue(figureId)
                        setDefaultModalStatusValue(status)
                        setCustomerIdToEdit(customerId)
                        handleAddFigureButtonClick(customerId)
                    }
                    else {
                        console.log(error)
                    }
                }
            );
    }

    const handleCustomerFilterChange = (event) => {
        const value = parseInt(event.target.value);
        const isChecked = event.target.checked;

        if (isChecked) {
            setCustomerFilters((prevFilters) => [...prevFilters, value]);
        }
        else {
            setCustomerFilters((prevFilters) =>
                prevFilters.filter((filter) => filter !== value)
            );
        }
    }

    const handleThematicFilterChange = (event) => {
        const value = parseInt(event.target.value);
        const isChecked = event.target.checked;

        if (isChecked) {
            setThematicFilters((prevFilters) => [...prevFilters, value]);
        }
        else {
            setThematicFilters((prevFilters) =>
                prevFilters.filter((filter) => filter !== value)
            );
        }
    }

    const handleStatusFilterChange = (event) => {
        const value = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            setStatusFilters((prevFilters) => [...prevFilters, value]);
        }
        else {
            setStatusFilters((prevFilters) =>
                prevFilters.filter((filter) => filter !== value)
            );
        }
    }

    const handleClickShowComments = (event) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            setShowComments(true)
        }
        else {
            setShowComments(false)
        }
    }

    return <Fragment>
        <Box>
            <div>Customer</div>
            <div className="flex flex-wrap my-4">
                {customers &&
                customers.map((option) => (
                    <label key={option.id} className="flex items-center mr-2" style={{width : "250px", whiteSpace : "nowrap"}}>
                        <input
                            type="checkbox"
                            value={option.id}
                            checked={customerFilters.includes(option.id)}
                            onChange={handleCustomerFilterChange}
                            className="mr-1"
                        />
                        {option.firstName} {option.lastName}
                    </label>
                ))}
            </div>
            <div>Thematic</div>
            <div className="flex flex-wrap my-4">
                {thematics &&
                thematics.map((option) => (
                    <label key={option.id} className="flex items-center mr-2" style={{width : "250px", whiteSpace : "nowrap"}}>
                        <input
                            type="checkbox"
                            value={option.id}
                            checked={thematicFilters.includes(option.id)}
                            onChange={handleThematicFilterChange}
                            className="mr-1"
                        />
                        {option.name}
                    </label>
                ))}
            </div>
            <div>Status</div>
            <div className="flex flex-wrap my-4">
                {statuses &&
                statuses.map((option) => (
                    <label key={option.id} className="flex items-center mr-2" style={{width : "250px", whiteSpace : "nowrap"}}>
                        <input
                            type="checkbox"
                            value={option.id}
                            checked={statusFilters.includes(option.id)}
                            onChange={handleStatusFilterChange}
                            className="mr-1"
                        />
                        {option.name}
                    </label>
                ))}
            </div>
            <div className="flex flex-wrap my-4">
                <label key={1} className="flex items-center mr-2" style={{width : "250px", whiteSpace : "nowrap"}}>
                    <input
                        type="checkbox"
                        value={true}
                        onChange={handleClickShowComments}
                        className="mr-1"
                        defaultChecked={showComments}
                    />
                    Show comments
                </label>
            </div>
            <FigureProgressTrackerTable
                customerIds={customerFilters}
                thematicIds={thematicFilters}
                selectedStatues={statusFilters}
                handleStatusButtonIconClick={handleStatusButtonIconClick}
                submitCount={submitCount}
                showComments={showComments}
            />
        </Box>
        {
            showModal && <CustomerFigureModal
                isUpdate={isUpdate}
                handleAddSubmit={handleAddSubmit}
                handleEditSubmit={handleEditSubmit}
                handleCancel={function () {
                    setShowModal(false);
                }}
                figures={figures}
                defaultModalFigureValue={defaultModalFigureValue}
                defaultModalStatusValue={defaultModalStatusValue}
                customerFigure={customerFigure}
                customerId={customerIdToEdit}
            />
        }
    </Fragment>
}

export default CustomerThematicFigureList;