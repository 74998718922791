import React, {Fragment} from "react";
import Pagination from "../layout/Pagination";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faEye, faCircleXmark, faFileInvoice, faDownload, faEnvelope, faCancel, faUndo} from "@fortawesome/free-solid-svg-icons";

const getFunctionArgs = (paramKeys, object) => {
    return paramKeys.map(key => {
        const nestedKeys = key.split('.');
        let value = object;
        nestedKeys.forEach(nestedKey => {
            value = value[nestedKey];
        });
        return value;
    });
}

const ListEdit = (props) => {
    return (
        <Fragment>
            <table className="w-full bg-white shadow-md rounded my-4">
                <thead>
                    <tr className="border-b">
                        {props.headers.map((header, index) => (
                            <th key={index} className={header === 'Actions' ? "text-center p-3 px-5" : "text-left p-3 px-5"}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {
                        props.items.map((item, index) => {
                            let args = getFunctionArgs(props.columns.functionParams, item);

                            return (
                                <tr key={index} className="border-b hover:bg-gray-100">
                                    {
                                        props.columns
                                        && props.columns.dataKeys
                                        && props.columns.dataKeys.map((dataKey, index) => (
                                            <td key={index} className="p-2 px-5">{item[dataKey]}</td>
                                        ))
                                    }
                                    {
                                        props.columns
                                        && props.columns.functionParams
                                        && (
                                            (props.handleEditIconClick)
                                            || (props.handleShowIconClick)
                                            || (props.handleDeleteIconClick)
                                            || (props.handleCancelIconClick)
                                            || (props.handleInvoiceIconClick)
                                            || (props.handleDownloadIconClick)
                                            || (props.handleEnvelopeIconClick)
                                            || (props.handleUncancelIconClick)
                                        )
                                        &&
                                        <td className="p-2 px-5 flex justify-evenly items-center space-x-4">
                                            {props.handleEditIconClick && (
                                                <div className="hover:cursor-pointer" title="Edit">
                                                    <FontAwesomeIcon
                                                        onClick={() => {
                                                            props.handleEditIconClick(...args);
                                                        }}
                                                        icon={faEdit}
                                                        className="text-lg"
                                                    />
                                                </div>
                                            )}
                                            {props.handleShowIconClick && (
                                                <div className="hover:cursor-pointer" title="View">
                                                    <FontAwesomeIcon
                                                        onClick={() => {
                                                            props.handleShowIconClick(...args);
                                                        }}
                                                        icon={faEye}
                                                        className="text-lg"
                                                    />
                                                </div>
                                            )}
                                            {props.handleDeleteIconClick && (
                                                <div className="hover:cursor-pointer" title="Delete">
                                                    <FontAwesomeIcon
                                                        onClick={() => {
                                                            props.handleDeleteIconClick(...args);
                                                        }}
                                                        icon={faCircleXmark}
                                                        className="text-lg"
                                                    />
                                                </div>
                                            )}
                                            {props.handleCancelIconClick && (
                                                <div className="hover:cursor-pointer" title="Cancel">
                                                    <FontAwesomeIcon
                                                        onClick={() => {
                                                            props.handleCancelIconClick(...args);
                                                        }}
                                                        icon={faCancel}
                                                        className="text-lg"
                                                    />
                                                </div>
                                            )}
                                            {props.handleUncancelIconClick && (
                                                <div className="hover:cursor-pointer" title="Undo Cancellation">
                                                    <FontAwesomeIcon
                                                        onClick={() => {
                                                            props.handleUncancelIconClick(...args);
                                                        }}
                                                        icon={faUndo}
                                                        className="text-lg"
                                                    />
                                                </div>
                                            )}
                                            {props.handleInvoiceIconClick && (
                                                <div className="hover:cursor-pointer" title="Make Invoice">
                                                    <FontAwesomeIcon
                                                        onClick={() => {
                                                            props.handleInvoiceIconClick(...args);
                                                        }}
                                                        icon={faFileInvoice}
                                                        className="text-lg"
                                                    />
                                                </div>
                                            )}
                                            {props.handleDownloadIconClick && (
                                                <div className="hover:cursor-pointer" title="Download">
                                                    <FontAwesomeIcon
                                                        onClick={() => {
                                                            props.handleDownloadIconClick(...args);
                                                        }}
                                                        icon={faDownload}
                                                        className="text-lg"
                                                    />
                                                </div>
                                            )}
                                            {props.handleEnvelopeIconClick && (
                                                <div className="hover:cursor-pointer" title="Send Email">
                                                    <FontAwesomeIcon
                                                        onClick={() => {
                                                            props.handleEnvelopeIconClick(...args);
                                                        }}
                                                        icon={faEnvelope}
                                                        className="text-lg"
                                                    />
                                                </div>
                                            )}
                                        </td>
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {props.pagination && (<Pagination
                currentPage={props.pagination.currentPage}
                itemsPerPage={props.pagination.perPage}
                totalItems={props.pagination.total}
                onPageChange={props.handlePageChange}
                onPageSizeChange={props.handlePageSizeChange}
            />)}
        </Fragment>
    );
}

export default ListEdit;