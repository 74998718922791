import {useEffect, useState} from "react";
import {findCustomerProductsWithPagination} from "../api/eventGroupApiClient";

export const useCustomerProducts = (customerId, filterFirstName, filterLastName, page, pageSize, reloadTrigger) => {
    const [customerProducts, setCustomerProducts] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const customerProductsWithPagination = findCustomerProductsWithPagination(page, pageSize, customerId, filterFirstName, filterLastName, abortController.signal);

        customerProductsWithPagination
            .then(
                (data) => {
                    const customerProducts = data.customerProducts;
                    const pagination = data.pagination;

                    setCustomerProducts(customerProducts)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [customerId, filterFirstName, filterLastName, page, pageSize, reloadTrigger]);

    return {customerProducts : customerProducts, pagination};
};