import {useEffect, useState} from "react";
import {findProductValidityUnits} from "../api/eventGroupApiClient";

export const useProductValidityUnits = (reloadTrigger) => {
    const [productValidityUnits, setProductValidityUnits] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        const productValidityUnits = findProductValidityUnits(abortController.signal);

        productValidityUnits
            .then(
                (data) => {
                    const productValidityUnits = data;

                    setProductValidityUnits(productValidityUnits)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [reloadTrigger]);

    return {productValidityUnits : productValidityUnits};
};