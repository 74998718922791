import React from "react";
import {useParams} from 'react-router-dom';
import EventSeriesList from "../../components/event/EventSeriesList";

const EventSeriesPage = () => {
    const {eventGroupId} = useParams();

    return <EventSeriesList eventGroupId={eventGroupId}/>
}

export default EventSeriesPage;