import {createBrowserRouter} from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import CustomersPage from "./pages/customer/CustomersPage";
import CustomerDetailPage from "./pages/customer/CustomerDetailPage";
import React from "react";
import FiguresPage from "./pages/figure/FiguresPage";
import FigureCategoriesPage from "./pages/figure/FigureCategoriesPage";
import Dashboard from "./components/layout/DashboardLayout";
import ThematicsPage from "./pages/thematic/ThematicsPage";
import ThematicFiguresPage from "./pages/thematic/ThematicFiguresPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import GripsPage from "./pages/figure/GripsPage";
import GuestLayout from "./components/layout/GuestLayout";
import LoginPage from "./pages/authentication/LoginPage";
import LevelsPage from "./pages/level/LevelsPage";
import ExamsPage from "./pages/exam/ExamsPage";
import ExamRequirementsPage from "./pages/exam/ExamRequirementsPage";
import LevelRequirementsPage from "./pages/level/LevelRequirementsPage";
import CustomerLevelRequirementSummariesPage from "./pages/customer/CustomerLevelRequirementSummariesPage";
import CustomerLevelRequirementsPage from "./pages/customer/CustomerLevelRequirementsPage";
import AllCustomerCommentsPage from "./pages/customer/AllCustomerCommentsPage";
import CustomerThematicPage from "./pages/customer/CustomerThematicPage";
import UsersPage from "./pages/user/UsersPage";
import UserDetailPage from "./pages/user/UserDetailPage";
import EventGroupsPage from "./pages/event/EventGroupsPage";
import EventSeriesPage from "./pages/event/EventSeriesPage";
import EventsPage from "./pages/event/EventsPage";
import EventBookingsPage from "./pages/event/EventBookingsPage";
import ProductsPage from "./pages/event/ProductsPage";
import BookingGroupsPage from "./pages/event/BookingGroupsPage";
import CustomerProductsPage from "./pages/event/CustomerProductsPage";
import PaymentsPage from "./pages/event/PaymentsPage";
import OrdersPage from "./pages/event/OrdersPage";
import InvoicesPage from "./pages/event/InvoicesPage";

const router = createBrowserRouter([
    {
        path         : "/",
        element      : <Dashboard/>,
        errorElement : <ErrorPage/>,
        children     : [
            {
                path    : "/",
                element : <DashboardPage/>,
            },
            {
                path    : "/users",
                element : <UsersPage/>,
            },
            {
                path    : "/users/:userId",
                element : <UserDetailPage/>,
            },
            {
                path    : "/customers",
                element : <CustomersPage/>,
            },
            {
                path    : "/customer-thematics",
                element : <CustomerThematicPage/>,
            },
            {
                path    : "/customer-level-requirement-summaries",
                element : <CustomerLevelRequirementSummariesPage/>,
            },
            {
                path    : "/customer-level-requirements/:customerId",
                element : <CustomerLevelRequirementsPage/>,
            },
            {
                path    : "/customers/:customerId",
                element : <CustomerDetailPage/>,
            },
            {
                path    : "/all-customer-comments",
                element : <AllCustomerCommentsPage/>,
            },
            {
                path    : "/figures",
                element : <FiguresPage/>,
            },
            {
                path    : "/figure-categories",
                element : <FigureCategoriesPage/>,
            },
            {
                path    : "/grips",
                element : <GripsPage/>,
            },
            {
                path    : "/levels",
                element : <LevelsPage/>,
            },
            {
                path    : "/thematics",
                element : <ThematicsPage/>,
            },
            {
                path    : "/thematics/:thematicId/figures",
                element : <ThematicFiguresPage/>,
            },
            {
                path    : "/exams",
                element : <ExamsPage/>,
            },
            {
                path    : "/exams/:examId/requirements",
                element : <ExamRequirementsPage/>,
            },
            {
                path    : "/levels/:levelId/requirements",
                element : <LevelRequirementsPage/>,
            },
            {
                path    : "/event-groups",
                element : <EventGroupsPage/>,
            },
            {
                path    : "/event-groups/:eventGroupId/event-series",
                element : <EventSeriesPage/>,
            },
            {
                path    : "/events",
                element : <EventsPage/>,
            },
            {
                path    : "/events/:eventId/bookings",
                element : <EventBookingsPage/>,
            },
            {
                path    : "/products",
                element : <ProductsPage/>,
            },
            {
                path    : "/booking-groups",
                element : <BookingGroupsPage/>,
            },
            {
                path    : "/customer-products",
                element : <CustomerProductsPage/>,
            },
            {
                path    : "/payments",
                element : <PaymentsPage/>,
            },
            {
                path    : "/orders",
                element : <OrdersPage/>,
            },
            {
                path    : "/invoices",
                element : <InvoicesPage/>,
            },
        ],
    },
    {
        path         : '/',
        element      : <GuestLayout/>,
        errorElement : <ErrorPage/>,
        children     : [
            {
                path    : '/login',
                element : <LoginPage/>
            }
        ]
    },
    {
        path    : '*',
        element : <div>404 - Page Not Found</div>
    }
]);

export default router;