import {API_URL, handleErrorResponse} from "../utility/utility";

export async function findCustomersWithPagination(page, limit, filterFirstName, filterLastName, status = null, filterEmailExistence = null, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    if (filterFirstName) {
        params.append('firstName', filterFirstName);
    }

    if (filterLastName) {
        params.append('lastName', filterLastName);
    }

    if (status) {
        params.append('status', status);
    }

    if (filterEmailExistence) {
        params.append('emailExistence', filterEmailExistence);
    }

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/customers?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch customers');

    return response.json();
}

export async function getCustomer(customerId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/customers/${customerId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch customer');

    return response.json();
}

export async function getCustomerLevel(customerId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/customers/${customerId}/level`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch customer level');

    return response.json();
}

export async function createCustomerLevel(customerId, levelId, happenedAt) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const data = {
        levelId    : levelId,
        happenedAt : happenedAt
    }

    const response = await fetch(`${API_URL}/api/customers/${customerId}/level`, {
        method  : 'POST',
        body    : JSON.stringify(data),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create customer level');
}

export async function updateCustomerLevel(customerId, levelId, happenedAt) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const data = {
        levelId    : levelId,
        happenedAt : happenedAt
    }

    const response = await fetch(`${API_URL}/api/customers/${customerId}/level`, {
        method  : 'PATCH',
        body    : JSON.stringify(data),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update customer level');
}

export async function createCustomer(firstName, lastName, email, phone, status) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const data = {
        firstName : firstName,
        lastName  : lastName,
        email     : email,
        phone     : phone,
        status    : status
    }

    const response = await fetch(`${API_URL}/api/customers`, {
        method  : 'POST',
        body    : JSON.stringify(data),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create customer');
}

export async function updateCustomer(customerId, firstName, lastName, email, phone, status) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const data = {}

    if (firstName) {
        data.firstName = firstName;
    }

    if (lastName) {
        data.lastName = lastName;
    }

    if (email) {
        data.email = email;
    }

    if (phone) {
        data.phone = phone;
    }

    if (status) {
        data.status = status;
    }

    const response = await fetch(`${API_URL}/api/customers/${customerId}`, {
        method  : 'PATCH',
        body    : JSON.stringify(data),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update customer');
}

export async function findCustomerFiguresWithPagination(customerId, page, limit, figureName, status, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);
    params.append('customerId', customerId)

    if (figureName) {
        params.append('figureName', figureName);
    }

    if (status) {
        params.append('status', status);
    }

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/customer-figures?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch customer figures');

    return response.json();
}

export async function saveCustomerFigure(customerId, figureId, status, happenedAt, comment) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/customer-figures`, {
        method  : 'POST',
        body    : JSON.stringify({
            customerId : customerId,
            figureId   : figureId,
            status     : status,
            happenedAt : happenedAt,
            comment    : comment
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create customer figure');
}

export async function findCustomerNotAssignedFiguresWithPagination(customerId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', 1);
    params.append('limit', 1000);
    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/customer-figures/${customerId}/not-assigned?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch not assigned figures');

    return await response.json();
}

export async function getCustomerFigure(customerId, figureId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/customer-figures/${customerId}/${figureId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch customer figure');

    return await response.json();
}

export async function deleteCustomerFigure(customerId, figureId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/customer-figures/${customerId}/${figureId}`, {
        method  : 'DELETE',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to delete customer figure');
}

export async function findCustomerCommentsWithPagination(page, limit, customerId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    if (customerId !== null) {
        params.append('customerId', customerId);
    }

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/customer-comments?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch customer comments');

    return response.json();
}

export async function getCustomerComment(customerCommentId, customerId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('customerId', customerId);

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/customer-comments/${customerCommentId}?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch customer comment');

    return response.json();
}

export async function createCustomerComment(customerId, comment) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const data = {
        customerId : customerId,
        comment    : comment
    }

    const response = await fetch(`${API_URL}/api/customer-comments`, {
        method  : 'POST',
        body    : JSON.stringify(data),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create customer comment');
}

export async function updateCustomerComment(customerId, customerCommentId, comment) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const data = {
        customerId : customerId,
        comment    : comment
    }

    const response = await fetch(`${API_URL}/api/customer-comments/${customerCommentId}`, {
        method  : 'PATCH',
        body    : JSON.stringify(data),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update customer comment');
}

export async function findCustomerFigureCommentHistoriesWithPagination(page, limit, customerId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);
    params.append('customerId', customerId);

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/customer-figure-comment-histories?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch customer figure comment histories');

    return response.json();
}

export async function findCustomerLevelRequirementSummariesWithPagination(page, limit, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/customer-level-requirement-summaries?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch customer level requirement summaries');

    return response.json();
}

export async function findCustomerLevelRequirementsWithPagination(customerId, page, limit, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/customer-level-requirements/${customerId}?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch customer level requirements');

    return response.json();
}