import {useEffect, useState} from "react";
import {findEventGroupsWithPagination} from "../api/eventGroupApiClient";

export const useEventGroups = (filterStatus, page, pageSize, reloadTrigger) => {
    const [eventGroups, setEventGroups] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const eventGroupsWithPagination = findEventGroupsWithPagination(page, pageSize, filterStatus, abortController.signal);

        eventGroupsWithPagination
            .then(
                (data) => {
                    const eventGroups = data.eventGroups;
                    const pagination = data.pagination;

                    setEventGroups(eventGroups)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [filterStatus, page, pageSize, reloadTrigger]);

    return {eventGroups : eventGroups, pagination};
};