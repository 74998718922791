import {Fragment, useRef, useState} from 'react';
import Button from "../UI/Button";
import React from "react";
import Modal from "../UI/Modal";
import {createPayment} from "../../api/eventGroupApiClient";
import ListEdit from "../UI/ListEdit";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import Box from "../UI/Box";
import {toast} from "react-toastify";
import {convertToDisplayDate} from "../../utility/utility";
import {useCustomers} from "../../hooks/useCustomers";
import {usePaymentMethods} from "../../hooks/usePaymentMethods";
import {usePayments} from "../../hooks/usePayments";
import {getCustomer} from "../../api/customerApiClient";
import {usePayableOrders} from "../../hooks/usePayableOrders";

const PaymentList = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [customer, setCustomer] = useState(null);
    const [submitCount, setSubmitCount] = useState(0);
    const [filterCustomerId, setFilterCustomerId] = useState(null);
    const selectOrderRef = useRef(null);
    const selectCustomerRef = useRef(null);
    const selectPaymentMethodRef = useRef(null);
    const paymentDateRef = useRef(null);
    const {payments, pagination} = usePayments(filterCustomerId, page, pageSize, submitCount);
    const {orders} = usePayableOrders(customer?.id, 0);
    const {customers} = useCustomers(1, 200, null, null, 'active', null, 0);
    const {paymentMethods} = usePaymentMethods(1, 0);

    const paymentList = payments.map((item) => {
        return {
            paymentId     : item.paymentId,
            number        : item.number,
            customerName  : item.customerName,
            paymentDate   : convertToDisplayDate(item.createdAt),
            paymentMethod : item.paymentMethod,
            productName   : item.text,
            bruttoPrice   : item.bruttoPrice + ' ' + item.currency,
        };
    });

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleCreateButtonClick = () => {
        setShowModal(true)
    }

    const handleCreateSubmit = (event) => {
        event.preventDefault();

        const customerId = selectCustomerRef.current.value;
        const orderId = selectOrderRef.current.value;
        const paymentDate = paymentDateRef.current.value;
        const paymentMethod = selectPaymentMethodRef.current.value;

        createPayment(
            customerId,
            orderId,
            paymentMethod,
            paymentDate
        )
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }

    const handleCustomerOnChange = (event) => {
        const customerId = event.target.value;
        const customer = getCustomer(customerId);
        customer
            .then((data) => setCustomer(data))
            .catch((error) => console.log(error));
    }

    const listHeaders = [
        'Id',
        'Number',
        'Customer name',
        'Payment date',
        'Payment method',
        'Product name',
        'Amount',
        'Actions'
    ];

    const columns = {
        functionParams : [
            'paymentId'
        ],
        dataKeys       : [
            'paymentId',
            'number',
            'customerName',
            'paymentDate',
            'paymentMethod',
            'productName',
            'bruttoPrice',
        ],
    }

    return <Fragment>
        <Box>
            <Button title='Create payment' handleClick={handleCreateButtonClick}/>
            <div className="flex my-4">
                <select
                    id="customerId"
                    name="customerId"
                    onChange={(event) => setFilterCustomerId(event.target.value)}
                    className="border p-2 mr-2"
                >
                    <option value={null}>Filter by customers</option>
                    {customers && customers.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.firstName} {option.lastName} {option.email ? `(${option.email})` : ''}
                        </option>
                    ))}
                </select>
            </div>
            <ListEdit
                headers={listHeaders}
                items={paymentList}
                columns={columns}
                pagination={pagination}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
            />
        </Box>
        {showModal && (<Modal>
            <form onSubmit={handleCreateSubmit}>
                <ModalForm
                    title={'Create payment'}
                    saveButtonTitle={'Create'}
                    handleCancelButtonClick={() => setShowModal(false)}
                >
                    <ModalFormInputRow title="Customer">
                        {customers && (
                            <select
                                ref={selectCustomerRef}
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                onChange={handleCustomerOnChange}
                            >
                                <option value={null}>Select customer</option>
                                {customers.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.firstName} {option.lastName} {option.email ? `(${option.email})` : ''}
                                    </option>
                                ))}
                            </select>
                        )}
                    </ModalFormInputRow>
                    <ModalFormInputRow title="Order">
                        {orders && (<select
                            ref={selectOrderRef}
                            className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                        >
                            <option value={null}>Select order</option>
                            {orders.map((option) => (<option key={option.orderId} value={option.orderId}>
                                {convertToDisplayDate(option.createdAt)} | {option.text} | {option.bruttoPrice}{option.currency} | {option.paymentStatus}
                            </option>))}
                        </select>)}
                    </ModalFormInputRow>
                    {(
                        <ModalFormInputRow title="Payment date">
                            <input
                                ref={paymentDateRef}
                                type="datetime-local"
                                id="paymentDate"
                                name="paymentDate"
                                defaultValue={new Date().toISOString().slice(0, 16)}
                                placeholder="Enter payment date"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                    )}
                    {(
                        <ModalFormInputRow title="Payment method">
                            {paymentMethods && (<select
                                ref={selectPaymentMethodRef}
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            >
                                {paymentMethods.map((option) => (<option key={option.paymentMethodId} value={option.name}>
                                    {option.name}
                                </option>))}
                            </select>)}
                        </ModalFormInputRow>
                    )}
                </ModalForm>
            </form>
        </Modal>)}
    </Fragment>;
}

export default PaymentList;