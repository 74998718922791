import ListEdit from "../UI/ListEdit";
import {Link} from "react-router-dom";
import React, {Fragment, useRef, useState} from "react";
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import Box from "../UI/Box";
import {toast} from "react-toastify";
import {createBooking, cancelBooking} from "../../api/eventGroupApiClient";
import {BOOKING_STATUS_ACTIVE, convertToDisplayDateTime, getBookingStatuses} from "../../utility/utility";
import {useEvent} from "../../hooks/useEvent";
import {useBookings} from "../../hooks/useBookings";
import {useCustomers} from "../../hooks/useCustomers";

const EventBookingList = (props) => {
    const {event} = useEvent(props.eventId);
    const [showModal, setShowModal] = useState(false);
    const [showCancelBookingModal, setShowCancelBookingModal] = useState(false);
    const [cancelBookingId, setCancelBookingId] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [submitCount, setSubmitCount] = useState(0);
    const [filterStatus, setFilterStatus] = useState(BOOKING_STATUS_ACTIVE);
    const statusFilterRef = useRef(null);
    const {bookings, pagination} = useBookings(props.eventId, filterStatus, page, pageSize, submitCount);
    const customerSelectRef = useRef(null);
    const {customers} = useCustomers(1, 200, null, null, 'active', null, 0);
    const withProductRef = useRef(null);
    const withCreditRefundRef = useRef(null);

    const bookingList = bookings.map(
        (booking) => {
            return {
                bookingId   : booking.bookingId,
                customer    : booking.customerName,
                product     : booking.productName ? booking.productName + ' (' + booking.creditsLeft + ')' : 'No product',
                cancelledAt : booking.cancelledAt ? convertToDisplayDateTime(booking.cancelledAt) : ''
            }
        }
    );

    const customerSelectList = customers.map(
        (customer) => {
            return {
                customerId : customer.id,
                customer   : customer.firstName + ' ' + customer.lastName + ' (' + (customer.level?.name || 'no level') + ')'
            }
        }
    );

    const listHeaders = [
        'Customer name',
        'Product',
        'Cancelled At'
    ];

    const columns = {
        functionParams : [
            'bookingId'
        ],
        dataKeys       : [
            'customer',
            'product',
            'cancelledAt'
        ]
    }

    const handleCreateButtonClick = () => {
        setShowModal(true)
    }

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleAddSubmit = (event) => {
        event.preventDefault();

        const customerId = customerSelectRef.current.value;
        const withProduct = withProductRef.current.checked ? 1 : 0;

        createBooking(
            props.eventId,
            customerId,
            withProduct
        )
            .then(() => {
                setShowModal(false);
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleDeleteIconClick = (bookingId) => {
        setCancelBookingId(bookingId);
        setShowCancelBookingModal(true);
    }

    const handleCancelBookingSubmit = (event) => {
        event.preventDefault();
        // eslint-disable-next-line no-restricted-globals
        cancelBooking(cancelBookingId, withCreditRefundRef.current.checked ? 1 : 0)
            .then(() => {
                setSubmitCount(prevCount => prevCount + 1);
                setShowCancelBookingModal(false);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleStatusFilterChange = () => {
        setFilterStatus(statusFilterRef.current.value)
        setPage(1);
    }

    return (
        <Fragment>
            <Link to={'/events'}>Back</Link>
            <Box>
                {event && <h1>Event: {event.name} {convertToDisplayDateTime(event.startedAt)}</h1>}
                <Button title='Add booking' handleClick={handleCreateButtonClick}/>
                {<select ref={statusFilterRef} onChange={handleStatusFilterChange} className="border p-2 mr-2">
                    {
                        getBookingStatuses().map(
                            (option) => (
                                <option key={option.id} value={option.id}>{option.name}</option>
                            )
                        )
                    }
                </select>}
                <ListEdit
                    headers={listHeaders}
                    items={bookingList}
                    columns={columns}
                    pagination={pagination}
                    handleDeleteIconClick={handleDeleteIconClick}
                    handlePageChange={handlePageChange}
                    handlePageSizeChange={handlePageSizeChange}
                />
            </Box>
            {showModal && (
                <Modal>
                    <form onSubmit={handleAddSubmit}>
                        <ModalForm
                            title={'Add booking'}
                            saveButtonTitle={'Add'}
                            handleCancelButtonClick={() => setShowModal(false)}
                        >
                            <ModalFormInputRow title='Customer'>
                                <select ref={customerSelectRef} className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline">
                                    {
                                        customerSelectList.map(
                                            (option) => (
                                                <option key={option.customerId} value={option.customerId}>{option.customer}</option>
                                            )
                                        )
                                    }
                                </select>
                            </ModalFormInputRow>
                            <ModalFormInputRow title='With product'>
                                <input ref={withProductRef} type="checkbox" id="withProduct" name="withProduct" className="mr-2"/>
                            </ModalFormInputRow>
                        </ModalForm>
                    </form>
                </Modal>
            )}
            {showCancelBookingModal && (
                <Modal>
                    <form onSubmit={handleCancelBookingSubmit}>
                        <ModalForm
                            title={'Cancel booking'}
                            saveButtonTitle={'Cancel booking'}
                            handleCancelButtonClick={() => setShowCancelBookingModal(false)}
                        >
                            <ModalFormInputRow title='With credit refund'>
                                <input ref={withCreditRefundRef} type="checkbox" id="withCreditRefund" name="withCreditRefund" className="mr-2"/>
                            </ModalFormInputRow>
                        </ModalForm>
                    </form>
                </Modal>
            )}
        </Fragment>

    );
};

export default EventBookingList;