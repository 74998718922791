import {useEffect, useState} from "react";
import {findEventTypes} from "../api/eventGroupApiClient";

export const useEventTypes = (reloadTrigger) => {
    const [eventTypes, setEventTypes] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        const eventTypes = findEventTypes(abortController.signal);

        eventTypes
            .then(
                (data) => {
                    const eventTypes = data;

                    setEventTypes(eventTypes)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [reloadTrigger]);

    return {eventTypes : eventTypes};
};