import {useEffect, useState} from "react";
import {getOrdersTotal} from "../api/eventGroupApiClient";

export const useOrdersTotal = (fromDate, toDate, reloadTrigger = null) => {
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const abortController = new AbortController();
        const ordersTotal = getOrdersTotal(fromDate, toDate, abortController.signal);

        ordersTotal
            .then(
                (data) => {
                    setTotal(data)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [fromDate, toDate, reloadTrigger]);

    return {total : total};
};