import {Fragment, useRef, useState} from 'react';
import React from "react";
import Modal from "../UI/Modal";
import {cancelEvent, getEvent, uncancelEvent, updateEvent} from "../../api/eventGroupApiClient";
import ListEdit from "../UI/ListEdit";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import Box from "../UI/Box";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useTrainers} from "../../hooks/useTrainers";
import {useEventListItems} from "../../hooks/useEventListItems";
import {convertToDisplayDate, convertToDisplayDateTime, EVENT_STATUS_ACTIVE, EVENT_STATUS_CANCELLED} from "../../utility/utility";

const EventList = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showUncancelModal, setShowUncancelModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [eventData, setEventData] = useState(null);
    const [submitCount, setSubmitCount] = useState(0);
    const [cancelEventId, setCancelEventId] = useState(null);
    const [uncancelEventId, setUncancelEventId] = useState(null);
    const nameRef = useRef(null);
    const seatsRef = useRef(null);
    const startedAtRef = useRef(null);
    const endedAtRef = useRef(null);
    const trainerSelectRef = useRef(null);
    const fromDateFilterRef = useRef(null);
    const selectStatusFilterRef = useRef(null);
    const [filterStatus, setFilterStatus] = useState(EVENT_STATUS_ACTIVE);
    const [filterFromDate, setFilterFromDate] = useState((new Date()).toISOString().slice(0, 10) + 'T00:00');
    const {eventListItems, pagination} = useEventListItems(page, pageSize, filterFromDate, filterStatus, submitCount);
    const {trainers} = useTrainers(1, 100, 0);
    const navigate = useNavigate();

    const eventList = eventListItems.map((event) => {
        return {
            eventId      : event.eventId,
            name         : event.name,
            eventDate    : convertToDisplayDate(event.eventDate),
            startTime    : event.startTime,
            endTime      : event.endTime,
            trainerNames : event.trainerNames,
            locationName : event.locationName,
            bookings     : event.bookings,
            cancelledAt  : event.cancelledAt !== null ? convertToDisplayDateTime(event.cancelledAt) : null,
        };
    });

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleEditIconClick = (eventId) => {
        setIsUpdate(true);
        const event = getEvent(eventId);

        event
            .then(
                (data) => {
                    setEventData(data)
                    setShowModal(true)
                }
            )
            .catch(
                (error) => console.log(error)
            );
    }

    const handleCancelIconClick = (eventId) => {
        // eslint-disable-next-line no-restricted-globals
        setShowCancelModal(true);
        setCancelEventId(eventId);
    }

    const handleUncancelIconClick = (eventId) => {
        setShowUncancelModal(true);
        setUncancelEventId(eventId);
    }

    const handleShowIconClick = (eventId) => {
        navigate('/events/' + eventId + '/bookings');
    }

    const handleFromDateFilterChange = () => {
        setFilterFromDate(fromDateFilterRef.current.value)
        setPage(1);
    }

    const handleCancelSubmit = (event) => {
        event.preventDefault();

        cancelEvent(cancelEventId)
            .then(() => {
                setShowCancelModal(false);
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleUncancelSubmit = (event) => {
        event.preventDefault();

        const eventId = uncancelEventId;

        uncancelEvent(eventId)
            .then(() => {
                setShowUncancelModal(false);
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();

        const name = nameRef.current.value;
        const seats = seatsRef.current.value;
        const startedAt = startedAtRef.current.value;
        const endedAt = endedAtRef.current.value;
        const trainers = Array.from(trainerSelectRef.current.selectedOptions).map(option => option.value);

        updateEvent(
            eventData.eventId,
            name,
            seats,
            trainers,
            startedAt,
            endedAt
        )
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleStatusFilterChange = () => {
        setFilterStatus(selectStatusFilterRef.current.value)
        setPage(1);
    }

    const listHeaders = [
        'Id',
        'Name',
        'Event Date',
        'Start Time',
        'End Time',
        'Trainers',
        'Location',
        'Booking',
        'Cancelled At',
        'Actions'
    ];

    const columns = {
        functionParams : ['eventId'],
        dataKeys       : [
            'eventId',
            'name',
            'eventDate',
            'startTime',
            'endTime',
            'trainerNames',
            'locationName',
            'bookings',
            'cancelledAt',
        ],
    }

    return <Fragment>
        <Box>
            <div className="flex my-4">
                <input ref={fromDateFilterRef} defaultValue={filterFromDate} type="datetime-local" placeholder="Filter by from date" className="border p-2 mr-2" onChange={handleFromDateFilterChange}/>
                <select defaultValue={filterStatus} ref={selectStatusFilterRef} onChange={handleStatusFilterChange} className="border p-2 mr-2">
                    <option value="">Filter by status</option>
                    <option value={EVENT_STATUS_ACTIVE}>Active</option>
                    <option value={EVENT_STATUS_CANCELLED}>Cancelled</option>
                </select>
            </div>
            <ListEdit
                headers={listHeaders}
                items={eventList}
                columns={columns}
                pagination={pagination}
                handleEditIconClick={handleEditIconClick}
                handleShowIconClick={handleShowIconClick}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
                handleCancelIconClick={handleCancelIconClick}
                handleUncancelIconClick={handleUncancelIconClick}
            />
        </Box>
        {showUncancelModal && (
            <Modal>
                <form onSubmit={handleUncancelSubmit}>
                    <ModalForm
                        title={'Are you sure you want to uncancel this event?'}
                        saveButtonTitle={'OK'}
                        handleCancelButtonClick={() => setShowUncancelModal(false)}
                    >
                        <div className="flex flex-col">
                            <p className="text-sm text-gray-600">
                                This action will:
                            </p>
                            <ul className="list-disc list-inside text-sm text-gray-600">
                                <li>Make the event visible again on the website.</li>
                            </ul>
                        </div>
                    </ModalForm>
                </form>
            </Modal>
        )}
        {showCancelModal && (
            <Modal>
                <form onSubmit={handleCancelSubmit}>
                    <ModalForm
                        title={'Are you sure you want to cancel this event?'}
                        saveButtonTitle={'OK'}
                        handleCancelButtonClick={() => setShowCancelModal(false)}
                    >
                        <div className="flex flex-col">
                            <p className="text-sm text-gray-600">
                                This action will:
                            </p>
                            <ul className="list-disc list-inside text-sm text-gray-600">
                                <li>Remove all bookings associated with this event.</li>
                                <li>Restore the deducted credits to all customers who had a booking.</li>
                                <li>Hide this event from the website.</li>
                                <li>Notify all customers who had a booking via email about the cancellation.</li>
                            </ul>
                        </div>
                    </ModalForm>
                </form>
            </Modal>
        )}
        {showModal && (
            <Modal>
                <form onSubmit={handleEditSubmit}>
                    <ModalForm
                        title={'Update event'}
                        saveButtonTitle={'Update'}
                        handleCancelButtonClick={() => setShowModal(false)}
                    >
                        <ModalFormInputRow title='Name'>
                            <input
                                ref={nameRef}
                                type="text"
                                id="name"
                                name="name"
                                defaultValue={eventData.name}
                                placeholder="Enter name"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                        <ModalFormInputRow title='Started At'>
                            <input
                                ref={startedAtRef}
                                type="datetime-local"
                                id="startedAt"
                                name="startedAt"
                                defaultValue={eventData.startedAt}
                                placeholder="Enter started at"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                        <ModalFormInputRow title='Ended At'>
                            <input
                                ref={endedAtRef}
                                type="datetime-local"
                                id="endedAt"
                                name="endedAt"
                                defaultValue={eventData.endedAt}
                                placeholder="Enter ended at"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                        <ModalFormInputRow title='Seats'>
                            <input
                                ref={seatsRef}
                                type="number"
                                id="seats"
                                name="seats"
                                defaultValue={isUpdate ? eventData.seats : null}
                                placeholder="Enter seats"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                        <ModalFormInputRow title='Trainers'>
                            {trainers && eventData && <select defaultValue={isUpdate ? eventData.trainers.map(obj => obj.trainerId) : null} ref={trainerSelectRef} multiple={true} className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline">
                                {
                                    trainers.map(
                                        (option) => (
                                            <option key={option.trainerId} value={option.trainerId}>{option.displayName}</option>
                                        )
                                    )
                                }
                            </select>}
                        </ModalFormInputRow>
                    </ModalForm>
                </form>
            </Modal>
        )}
    </Fragment>;
}

export default EventList;