import {useEffect, useState} from "react";
import {findEventSeriesWithPagination} from "../api/eventGroupApiClient";

export const useEventSeries = (eventGroupId, filerStatus, page, pageSize, reloadTrigger) => {
    const [eventSeries, setEventSeries] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const eventSeriesWithPagination = findEventSeriesWithPagination(eventGroupId, page, pageSize, filerStatus, abortController.signal);

        eventSeriesWithPagination
            .then(
                (data) => {
                    const eventSeries = data.eventSeries;
                    const pagination = data.pagination;

                    setEventSeries(eventSeries)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [eventGroupId, filerStatus, page, pageSize, reloadTrigger]);

    return {eventSeriesListFromApi : eventSeries, pagination};
};