import {useEffect, useState} from "react";
import {findEventListItemsWithPagination} from "../api/eventGroupApiClient";

export const useEventListItems = (page, pageSize, filterFromDate, filterStatus, reloadTrigger) => {
    const [eventListItems, setEventListItems] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const eventsWithPagination = findEventListItemsWithPagination(page, pageSize, filterFromDate, filterStatus, abortController.signal);

        eventsWithPagination
            .then(
                (data) => {
                    const eventListItems = data.eventListItems;
                    const pagination = data.pagination;

                    setEventListItems(eventListItems)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [page, pageSize, filterFromDate, filterStatus, reloadTrigger]);

    return {eventListItems : eventListItems, pagination};
};